/* eslint-disable */
const getBaseButtonCSSMargin = (alignment) => {
  switch (alignment) {
    case 'start':
      return '0 auto 0 0';
    case 'center':
      return '0 auto';
    case 'end':
      return '0 0 0 auto';
    default:
      return null;
  }
};

const BaseCSSHelper = {
  getBaseButtonCSSMargin,
};

export {
  getBaseButtonCSSMargin,
};

export default BaseCSSHelper;
